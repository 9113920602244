import React from "react"
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const CardiacTwoColCopyLeftTwo = () => (
  <div className="cardiacTwoColCopyLeftTwo">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftCopy">
                <Row>
                    <Col>
                        <div className="copyLeftWrap">
                            <h2>Simplified ordering and<br />
                            inventory management</h2>
                            <p>LifeSync takes the complexity out of having to order and manage cables and leadwires from multiple vendors, allowing you to <Link to="/standardization/">standardize</Link> with one provider and reduce management of vendors, orders, and SKUs.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 2}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/ls-simplified-ordering.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default CardiacTwoColCopyLeftTwo
