import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const CardiacTwoColCopyLeft = () => (
  <div className="cardiacTwoColCopyLeft">
    <Container fluid>
        <Row>
            <Col sm={12} md={6} className="twoColCopyLeftCopy">
                <Row>
                    <Col>
                        <div className="copyLeftWrap">
                            <h2>Economical alternative</h2>
                            <p>Reduce costs while gaining industry-leading warranties, support, and customer care compared to OEM manufacturers. In turn, your organization can lower patient costs and improve caregiver efficiency.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col sm={12} md={6} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/image-economical-alternative.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="50"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default CardiacTwoColCopyLeft
