import React from "react"
import { Parallax } from 'react-parallax'

import MainNavBar from "../mainNavBar"
import CaptionCardiac from "./captionCardiac"

const HeaderCardiac = ({ siteTitleContact }) => (
  <Parallax
      bgImage={require('../../images/hero-cardiac-p.jpg')}
      bgImageAlt="LifeSync Cardiac"
      strength="150"
      className="cardiacParallax"
  >
    <header className="headerCardiac">
      <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
      <MainNavBar />
      <CaptionCardiac />
    </header>
  </Parallax>
)

export default HeaderCardiac
