import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CardiacCTAs = () => (
  <div className="cardiacCTAs">
    <Container>
        <Row>
            <Col>
                <h2>Find cardiac monitoring and leadwires</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="ctas">
                    <a href="http://store.advantagemed.com/index.php?page=products" className="cta" target="_blank" rel="noopener noreferrer">
                        <div className="text">View All<br />
                        Products</div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.73 14.73"><title>View All Products</title><g id="Layer_2" data-name="Layer 2"><g id="Page_02" data-name="Page 02"><path className="cls-1" d="M7.37,0a7.37,7.37,0,0,1,7.36,7.37,7.37,7.37,0,0,1-7.36,7.36A7.37,7.37,0,0,1,0,7.37,7.37,7.37,0,0,1,7.37,0Zm5,2.35a7.09,7.09,0,0,0-10,0,7.09,7.09,0,0,0,0,10,7.09,7.09,0,0,0,10,0,7.09,7.09,0,0,0,0-10ZM5.7,3.49v.82l3,2.93-3,3.13v.87l3.89-4Z"/></g></g></svg>
                    </a>
                    <a href="/contact/" className="cta">
                        <div className="text">Talk to a<br />
                        LifeSync Expert</div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.73 14.73"><title>View All Products</title><g id="Layer_2" data-name="Layer 2"><g id="Page_02" data-name="Page 02"><path className="cls-1" d="M7.37,0a7.37,7.37,0,0,1,7.36,7.37,7.37,7.37,0,0,1-7.36,7.36A7.37,7.37,0,0,1,0,7.37,7.37,7.37,0,0,1,7.37,0Zm5,2.35a7.09,7.09,0,0,0-10,0,7.09,7.09,0,0,0,0,10,7.09,7.09,0,0,0,10,0,7.09,7.09,0,0,0,0-10ZM5.7,3.49v.82l3,2.93-3,3.13v.87l3.89-4Z"/></g></g></svg>
                    </a>
                </div>
            </Col>
        </Row>
    </Container>
  </div>
)

export default CardiacCTAs
