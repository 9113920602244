import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MedixImg from '../../images/logo-medix.svg'
import CHSImg from '../../images/logo-chs.svg'

const CardiacDistributors = () => (
  <div className="cardiacDistributors">
    <Container>
        <Row>
            <Col>
                <h2>International Distributors</h2>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="distributors">
                    <a href="http://www.medix.com.ar/" className="distributor" target="_blank" rel="noopener noreferrer">
                        <div className="text">Argentina</div>
                        <div className="logoWrap">
                            <img src={MedixImg} alt="" style={{ }} className="img-fluid" />
                        </div>
                    </a>
                    <a href="https://www.chsltd.com/" className="distributor" target="_blank" rel="noopener noreferrer">
                        <div className="text">Canada</div>
                        <div className="logoWrap">
                            <img src={CHSImg} alt="" style={{ }} className="img-fluid" />
                        </div>
                    </a>
                </div>
            </Col>
        </Row>
    </Container>
  </div>
)

export default CardiacDistributors
