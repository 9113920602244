import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const CardiacTwoColCopyRight = () => (
  <div className="cardiacTwoColCopyRight">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 2}} className="twoColCopyRightCopy">
                <Row>
                    <Col>
                        <div className="copyRightWrap">
                            <h2>Reliable monitoring connections</h2>
                            <p>LifeSync Cardiac (formerly AMC/Advantage Medical Cables) is the industry-leading provider of cardiac patient monitoring and leadwires. When signal quality, reliability, and radiolucency are a must, LifeSync is the obvious choice.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftImage">
                <Parallax
                    bgImage={require('../../images/product-banner-2.jpg')}
                    bgImageAlt="LifeSync Products"
                    strength="20"
                >
                    <div />
                </Parallax>
            </Col>
        </Row>
    </Container>
  </div>
)

export default CardiacTwoColCopyRight
