import React from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CaptionCardiac = () => (
  <Container>
        <Row>
            <Col>
                <div className="captionCardiac">
                    <h1>Simplify and standardize your cardiac monitoring and connections</h1>
                    <p>Lower cost with unparalleled radiolucency and signal quality.</p>
                </div>
            </Col>
        </Row>
  </Container>
)

export default CaptionCardiac
