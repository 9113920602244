import React from "react"
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import IntroImgCardiac from '../../images/cardiac-proven-process.png'

const CardiacProvenProcess = () => (
  <div className="provenProcess" style={{paddingBottom:'0'}}>
    <Container className="sixsteps" style={{paddingTop:'0'}}>
      <Row>
        <Col className="introduction">
          <h3 style={{fontWeight: 'normal'}}>Standardization without the pain</h3>
          <p>Standardizing to one leadwire system ensures staff familiarity, inventory efficiencies, and streamlined procurement across the hospital. For many facilities, single-patient-use LifeSync Leadwire Systems are cost-neutral compared to reusable leadwires, making them an easy choice.</p>
          <p>However, standardizing or replacing cardiac cables and connections can feel like an enormous task. Our dedicated project managers and conversion teams simplify the job with a no-mystery process of inventory, training, supply chain management, and implementation. As a vertically-integrated manufacturer, we eliminate the potential of backorders and keep the standardization process running smoothly at every step.</p>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
            <div className="imgWrap">
              <img src={IntroImgCardiac} alt="Cardiac Proven Process" className="resp-img" />
            </div>
            <p className="provendescription">LifeSync Cardiac guides standardization with a four-phase plan.</p>
        </Col>
        <Col md={5}>
          <ol>
            <li>
              <h4>Discovery</h4>
              <ul className="descriptionSublist">
                <li>Biomedical challenges</li>
                <li>Infection control concerns</li>
                <li>Supply chain pain relief</li>
              </ul>
            </li>
            <li>
              <h4>Evaluation</h4>
              <ul className="descriptionSublist">
                <li>Clinical evaluation</li>
                <li>Value analysis committee</li>
                <li>Management objectives</li>
              </ul>
            </li>
            <li>
              <h4>Conversion</h4>
              <ul className="descriptionSublist">
                <li>Site survey</li>
                <li>Inventory and parameter agreement</li>
                <li>Manufactured standardized solution</li>
                <li>Staff in-service and go-live</li>
                <li>Ongoing standardization</li>
              </ul>
            </li>
            <li>
              <h4>Customer devotion</h4>
              <ul className="descriptionSublist">
                <li>Personalized account management</li>
                <li>Customized solutions</li>
                <li>Satisfaction assurance</li>
              </ul>
            </li>
          </ol>
        </Col>
      </Row>
      <Row className="cardiacPPFollow">
        <Col className="text-center">
          <hr />
          <p className="bold text-center">For details on how LifeSync can help you standardize all your ECG connections</p>
          <Link to="/standardization/">
            <Button variant="primary">Click Here</Button>
          </Link>
          <hr/>
        </Col>
      </Row>
    </Container>
  </div>
)

export default CardiacProvenProcess
