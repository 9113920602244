import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LayoutCardiac from "../components/Cardiac/layoutCardiac"
import SEO from "../components/seo"

import CardiacTwoColCopyRight from '../components/Cardiac/cardiacTwoColCopyRight'
import CardiacTwoColCopyLeft from '../components/Cardiac/cardiacTwoColCopyLeft'
import CardiacTwoColCopyRightTwo from '../components/Cardiac/cardiacTwoColCopyRightTwo'
import CardiacTwoColCopyLeftTwo from '../components/Cardiac/cardiacTwoColCopyLeftTwo'
import CardiacProvenProcess from '../components/Cardiac/cardiacProvenProcess'
import CardiacCategories from '../components/Cardiac/cardiacCategories'
import CardiacCTAs from '../components/Cardiac/cardiacCTAs'
import CardiacDistributors from '../components/Cardiac/cardiacDistributors'

const CardiacPage = () => (
  <LayoutCardiac>
    <SEO 
      title="LifeSync Cardiac | High-Quality Cardiac Monitoring Connections" 
      description="LifeSync Cardiac cables and leadwires provide superior radiolucency and signal quality for a lower cost than traditional OEM manufacturers."
      ogimg="https://www.lifesync.com/static/hero-home-p-68887cab881dc915bb49debf1d67f892.jpg"
      link="https://www.lifesync.com/cardiac/"
    />
    <CardiacTwoColCopyRight />
    <CardiacTwoColCopyLeft />
    <CardiacTwoColCopyRightTwo />
    <CardiacTwoColCopyLeftTwo />
    <CardiacProvenProcess />
    <CardiacCategories />
    <CardiacCTAs />
    <CardiacDistributors />
  </LayoutCardiac>
)

export default CardiacPage
