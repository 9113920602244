import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

const CardiacTwoColCopyRightTwo = () => (
  <div className="cardiacTwoColCopyRightTwo">
    <Container fluid>
        <Row>
            <Col md={{ span: 6, order: 2}} className="twoColCopyRightCopy">
                <Row>
                    <Col>
                        <div className="copyRightWrap">
                            <h2>Unmatched signal quality<br />
                            and radiolucency</h2>
                            <p>Achieve the most accurate monitoring and test results with cables and leadwires that deliver the industry's best signal quality. <a href="/radiolucent-leadwires/">Radiolucent</a> monitoring connections save caregivers time and ease the patient experience during their treatment.</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span: 6, order: 1}} className="twoColCopyLeftImage">
                <Row className="insideParallax">
                    <Col className="text-center">
                        <p class="overlaytext">Traditional<br />
                        leadwires</p>
                    </Col>
                    <Col className="text-center">
                        <p class="overlaytext">LifeSync radiolucent leadwires<br />
                        and LeadWear<sup>&reg;</sup></p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  </div>
)

export default CardiacTwoColCopyRightTwo
